import React, { useEffect } from "react"

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles"

import {
  CssBaseline,
  Container,
  Box,
  Typography,
  Grid,
  Hidden,
  Divider,
} from "@material-ui/core"

import "fontsource-lato"

import Logo from "src/images/logo-amaury-beaufort.svg"
import VolutesLeft from "src/images/volutes-left.svg"
import VolutesRight from "src/images/volutes-right.svg"

let theme = createMuiTheme({
  palette: {
    background: {
      default: "#FFFFFF",
    },
    text: {
      primary: "#828282",
      secondary: "#726D60",
    },
    primary: {
      light: "#646465",
      main: "#3E3E3F",
      dark: "#2B2B2C",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#EAE4D4",
      main: "#E5DECA",
      dark: "#A09B8D",
      contrastText: "#726D60",
    },
  },
  typography: {
    htmlFontSize: 18,
    fontSize: 18,
    fontFamily: "Lato, Roboto, sans-serif",
  },
})

theme = responsiveFontSizes(theme)

const Layout = props => {
  useEffect(() => {
    console.log("=== Layout ===")
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Box display="flex" flexDirection="column" height="100vh">
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          paddingY={3}
          display="flex"
          alignItems="center"
        >
          <Container maxWidth="sm">
            <Grid container justify="space-around" spacing={2}>
              <Hidden xsDown>
                <Grid item>
                  <img
                    src={VolutesLeft}
                    alt="VolutesLeft"
                    title="VolutesLeft"
                  />
                </Grid>
              </Hidden>

              <Grid item>
                <img src={Logo} alt="Logo" title="Logo" />
              </Grid>

              <Hidden xsDown>
                <Grid item>
                  <img
                    src={VolutesRight}
                    alt="VolutesRight"
                    title="VolutesRight"
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Container>
        </Box>

        <Box paddingY={6}>
          <Container maxWidth="sm">{props.children}</Container>
        </Box>

        <Box
          bgcolor="secondary.main"
          color="secondary.contrastText"
          paddingY={7}
          display="flex"
          alignItems="center"
          flexGrow={1}
        >
          <Container maxWidth="xs">
            <Typography variant="caption" style={{ textAlign: "center" }}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12}>
                  Artisan vinificateur à Bar sur Seine depuis 2019.
                  <br />
                  Champagne et Bourgogne bio issus de vignes cultivées sans
                  engrais chimique, sans désherbants, sans pesticides ni
                  fongicides de synthèse depuis 1971.
                </Grid>
                <Grid item xs={1}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  amaury-beaufort(à)hotmail.fr
                  <br />
                  Bar-sur-seine 10110
                  <br />
                  France
                </Grid>
              </Grid>
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Layout
